import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class Url  {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    public getUrlBase(): string {
        const portStr: string = this.document.location.port ?  `:${this.document.location.port}` : '';

        return `${this.document.location.protocol}//${this.document.location.hostname}${portStr}/`;
    }
}
