<div class="error-snackbar__title vm-flex-90">
    <span>{{data.message}}</span>
</div>

<div>
    <ul *ngIf="data.details.length > 1" class="error-snackbar__details-list">
        <li *ngFor="let detail of data.details">{{detail}}</li>
    </ul>
    <span *ngIf="data.details.length === 1">{{data.details[0]}}</span>
</div>

<button
    mat-icon-button
    class="error-snackbar__close-button"
    i18n-matTooltip="@@ERROR_SNACKBAR_CLOSE_BUTTON_TOOLTIP"
    matTooltip="ERROR_SNACKBAR_CLOSE_BUTTON_TOOLTIP"
    (click)="snackBarRef.dismiss()"
>
    <mat-icon>close</mat-icon>
</button>
