import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {AtvUserOptionsComponent} from '@shared/ui/navigation/user-options/user-options.component';
import {MatListModule} from '@angular/material/list';
import {KeycloakService} from 'keycloak-angular';
import {AtvDialogContainerModule} from '@shared/ui/dialogs/container/dialog-container.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    imports: [
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatListModule,
        AtvDialogContainerModule,
    ],
    declarations: [
        AtvUserOptionsComponent,
    ],
    exports: [
        AtvUserOptionsComponent,
    ],
    providers: [
        KeycloakService,
    ],
})
class AtvUserOptionsModule {
}

export {AtvUserOptionsModule};
