import {Inject, Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {DOCUMENT} from '@angular/common';
import {NotificationsService} from '@shared/services/notifications.service';
import {UserResourceAccessRoles} from '@domain-user/model/user';

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {
    constructor(
        protected readonly router: Router,
        protected readonly keycloakService: KeycloakService,
        @Inject(DOCUMENT) private document: Document,
        private notificationsService: NotificationsService,
    ) {
        super(router, keycloakService);
    }

    public async isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean> {
        // Force the user to log in if currently unauthenticated.
        if (!this.authenticated) {
            await this.keycloakService.login({
                redirectUri: this.document.location.href,
            });
        }

        // Get the roles required from the route.
        const requiredRoles: Array<string> = route.data.roles;

        return this.keycloakService.getToken()
            .then((token: string): boolean => {

                const decoded: any = JSON.parse(atob(token.split('.')[1]));
                const role: UserResourceAccessRoles = route.data.api || UserResourceAccessRoles.BookingApi;

                const roleIntersection: Array<string> =
                    requiredRoles.filter((value: string) => decoded.resource_access[role].roles.includes(value));

                if (roleIntersection.length === 0) {
                    const messageText: string = `User has no permission to access "${state.url}"`;
                    this.notificationsService.showSnackbar(messageText, 'ERROR');
                }

                return roleIntersection.length !== 0;
            });
    }
}
