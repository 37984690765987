<div>
    <button mat-icon-button type="button" [matMenuTriggerFor]="menu">
        <mat-icon class="localization-button" svgIcon='{{locale}}'></mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="menu-panel menu-panel--no-padding" xPosition="before">
        <mat-selection-list
            class="selection-menu"
            *ngFor="let option of languageOptions"
            hideSingleSelectionIndicator="true"
            multiple="false"
        >
            <mat-list-item (click)="switchLanguage(option.key)">
                <mat-icon
                    matListItemIcon
                    class="localization-button"
                    svgIcon="{{option.key}}"
                >
                </mat-icon>
                {{option.value}}
            </mat-list-item>
        </mat-selection-list>
    </mat-menu>
</div>
