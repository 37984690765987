import {IResponseMessages} from '@domain-api/response/response-messages';

export function getMessagesForComponent(component: string): Map<string, IResponseMessages> {
    return new Map([
        [
            'GET',
            {
                error: $localize`${component}:component:_GET_ERROR_MESSAGE`,
            },
        ],
        [
            'POST',
            {
                success: $localize`${component}:component:_ADD_SUCCESSFUL_MESSAGE`,
                error: $localize`${component}:component:_ADD_ERROR_MESSAGE`,
            },
        ],
        [
            'PUT',
            {
                success: $localize`${component}:component:_UPDATE_SUCCESSFUL_MESSAGE`,
                error: $localize`${component}:component:_UPDATE_ERROR_MESSAGE`,
            },
        ],
        [
            'DELETE',
            {
                success: $localize`${component}:component:_DELETE_SUCCESSFUL_MESSAGE`,
                error: $localize`${component}:component:_DELETE_ERROR_MESSAGE`,
            },
        ],
    ]);
}
