/* eslint-disable @typescript-eslint/typedef */
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@core/auth.guard';
import {ReportingUserRoles, UserResourceAccessRoles, UserRoles} from '@domain-user/model/user';
import {AtvPageNotFoundComponent} from '@shared/ui/page-not-found/page-not-found.component';
import {redirectGuard, tenantIdResolver} from '@core/redirect.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard, redirectGuard],
    },
    {
        path: ':id',
        resolve: {
            tenantId: tenantIdResolver,
        },
        children: [
            {
                path: 'insertion-order',
                loadChildren: () => import('./domains/insertion-order/insertion-order.module').then(m => m.AtvInsertionOrderModule),
                data: {roles: [UserRoles.Visitor]},
                canActivate: [AuthGuard],
            },
            {
                path: 'adspot',
                loadChildren: () => import('./domains/adspot/adspot.module').then(m => m.AtvAdspotModule),
                data: {roles: [UserRoles.Visitor]},
                canActivate: [AuthGuard],
            },
            {
                path: 'fillerspot',
                loadChildren: () => import('./domains/fillerspot/fillerspot.module').then(m => m.AtvFillerspotModule),
                data: {roles: [UserRoles.Visitor]},
                canActivate: [AuthGuard],
            },
            {
                path: 'company',
                loadChildren: () => import('./domains/company/company.module').then(m => m.AtvCompanyModule),
                data: {roles: [UserRoles.Visitor]},
                canActivate: [AuthGuard],
            },
            {
                path: 'industry',
                loadChildren: () => import('./domains/industry/industry.module').then(m => m.AtvIndustryModule),
                data: {roles: [UserRoles.Visitor]},
                canActivate: [AuthGuard],
            },
            {
                path: 'adblock',
                loadChildren: () => import('./domains/adblock/adblock.module').then(m => m.AtvAdblockModule),
                data: {roles: [UserRoles.Visitor]},
                canActivate: [AuthGuard],
            },
            {
                path: 'audience',
                loadChildren: () => import(
                    './domains/audience-management/audience-management.module'
                ).then(m => m.AtvAudienceManagementModule),
                data: {roles: [UserRoles.Visitor]},
                canActivate: [AuthGuard],
            },
            {
                path: 'channel',
                loadChildren: () => import('./domains/channel/channel.module').then(m => m.AtvChannelModule),
                data: {roles: [UserRoles.Visitor]},
                canActivate: [AuthGuard],
            },
            {
                path: 'content',
                loadChildren: () => import('./domains/content/content.module').then(m => m.AtvContentModule),
                data: {roles: [UserRoles.Visitor]},
                canActivate: [AuthGuard],
            },
            {
                path: 'tags',
                loadChildren: () => import('./domains/tags/tags.module').then(m => m.AtvTagsModule),
                data: {roles: [UserRoles.Visitor]},
                canActivate: [AuthGuard],
            },
            {
                path: 'schedule',
                loadChildren: () => import('./domains/schedule/schedule.module').then(m => m.AtvScheduleModule),
                data: {roles: [UserRoles.Visitor]},
                canActivate: [AuthGuard],
            },
            {
                path: 'history',
                loadChildren: () => import('./domains/history/history.module').then(m => m.HistoryModule),
                data: {roles: [UserRoles.Visitor]},
                canActivate: [AuthGuard],
            },
            {
                path: 'report',
                loadChildren: () => import('./domains/reporting/report.module').then(m => m.AtvReportingModule),
                data: {roles: [ReportingUserRoles.Visitor], api: UserResourceAccessRoles.ReportingApi},
                canActivate: [AuthGuard],
            },
            // Wildcard route for a 404 page
            {
                path: '**',
                component: AtvPageNotFoundComponent,
                data: {roles: [UserRoles.Visitor]},
                canActivate: [AuthGuard],
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            paramsInheritanceStrategy: 'always',
            bindToComponentInputs: true,
        }),
    ],
    providers: [
        AuthGuard,
    ],
    exports: [RouterModule],
})

class AppRoutingModule {
}

export {AppRoutingModule, routes};
