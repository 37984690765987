import {KeycloakService} from 'keycloak-angular';
import {environment} from '@env';

export function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
    return () =>
        keycloak.init({
            config: {
                url: `${window.location.protocol}//${environment.keycloakUrl}`,
                realm: 'adition-tv',
                clientId: 'booking-gui',
            },
            initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                    `${window.location.origin}/shared/silent-check-sso.html`,
            },
            loadUserProfileAtStartUp: true,
        });
}
