import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogConfig, MatDialogModule} from '@angular/material/dialog';
import {AtvDialogContainerComponent} from '@shared/ui/dialogs/container/dialog-container.component';
import {CdkStepper} from '@angular/cdk/stepper';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
        AtvDialogContainerComponent,
    ],
    exports: [
        AtvDialogContainerComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
    ],
    providers: [
        MatDialogConfig,
        CdkStepper,
    ],
})
export class AtvDialogContainerModule {
}
