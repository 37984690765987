import {NgModule} from '@angular/core';
import {CoreAgGridLinkComponent} from './link.component';
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        RouterModule,
    ],
    exports: [
        CoreAgGridLinkComponent,
        RouterModule,
    ],
    declarations: [
        CoreAgGridLinkComponent,
    ],
})
export class CoreAgGridLinkModule {
}
