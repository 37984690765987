import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';

@Component({
    selector: 'atv-tenant-switch-dialog',
    template: `
        <ng-template #content></ng-template>
    `,
    standalone: true,
})
class AtvTenantSwitchDialogComponent implements OnInit {
    @ViewChild('content', {read: ViewContainerRef}) public content!: ViewContainerRef;
    public ngOnInit(): void {
        void this.loadContent();
    }

    private async loadContent(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/typedef
        const {AtvTenantSwitchComponent} = await import('./tenant-switch.component');
        this.content.clear();
        this.content.createComponent(AtvTenantSwitchComponent);
    }

}

export {AtvTenantSwitchDialogComponent};
