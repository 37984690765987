import {NgModule} from '@angular/core';
import {AdContentComponent} from './application-content.component';

@NgModule({
    declarations: [
        AdContentComponent,
    ],
    exports: [
        AdContentComponent,
    ],
})
class ApplicationContentModule {
}

export {ApplicationContentModule};
