<mat-dialog-content>
    <p *ngIf="data.title" mat-dialog-title>{{data.title}}</p>
    <p>{{data.description}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button type="button" [mat-dialog-close]=false *ngIf="data.hasCancelButton">
        <span *ngIf="!data.cancelButtonLabel" i18n="@@DIALOG_CANCEL_BUTTON">DIALOG_CANCEL_BUTTON</span>
        <span *ngIf="data.cancelButtonLabel">{{data.cancelButtonLabel}}</span>
    </button>
    <button mat-button type="button" cdkFocusInitial [mat-dialog-close]=true color="primary" data-cy="dialog-ok-button">
        <span *ngIf="!data.confirmButtonLabel" i18n="@@DIALOG_OK_BUTTON">DIALOG_OK_BUTTON</span>
        <span *ngIf="data.confirmButtonLabel">{{data.confirmButtonLabel}}</span>
    </button>
</mat-dialog-actions>
