export class LocalStorageService {

    public static getLocalStorage(key: string): any {
        const content: string | null = localStorage.getItem((key));

        return content ? JSON.parse(content) : null;
    }

    public static setLocalStorage(key: string, data: unknown): void {
        localStorage.setItem(key, JSON.stringify(data));
    }

}
