import {NgModule} from '@angular/core';
import {RequestLoadingIndicatorComponent} from './request-loading-indicator.component';
import {CommonModule} from '@angular/common';
import {LoadingService} from '@shared/ui/request-loading-indicator/service/loading.service';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
    imports: [CommonModule, MatProgressBarModule],
    exports: [RequestLoadingIndicatorComponent],
    declarations: [RequestLoadingIndicatorComponent],
    providers: [LoadingService],
})
export class AtvRequestLoadingIndicatorModule { }
