import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {UserRoles} from '@domain-user/model/user';
import {from, Observable, ReplaySubject, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RolesService {
    public static hasAdminPermissions: boolean = false;
    public static hasCreatorPermissions: boolean = false;
    public static hasEditorPermissions: boolean = false;
    public static hasAudienceManagerPermissions: boolean = false;
    private static apiRoles: Subject<Array<UserRoles>> = new ReplaySubject<Array<UserRoles>>(1);

    constructor(
        private keycloakService: KeycloakService,
    ) {
    }

    public static getApiRoles(): Observable<Array<UserRoles>> {
        return RolesService.apiRoles.asObservable();
    }

    public init(): void {
        this.getUserPermissions();
        from<Promise<string>>(this.keycloakService.getToken())
            .pipe(
                tap((token: string): void => {
                    const decoded: IGenericObject = JSON.parse(atob(token.split('.')[1]));
                    RolesService.apiRoles.next(decoded.resource_access['booking-api'].roles);
                }),
            )
            .subscribe();
    }

    private getUserPermissions(): void {
        RolesService.getApiRoles().subscribe(
            (roles: Array<string>) => {
                RolesService.hasEditorPermissions = roles.includes(UserRoles.Editor);
                RolesService.hasCreatorPermissions = roles.includes(UserRoles.Creator);
                RolesService.hasAdminPermissions = roles.includes(UserRoles.Admin);
                RolesService.hasAudienceManagerPermissions = roles.includes(UserRoles.AudienceManager);
            },
        );
    }
}
