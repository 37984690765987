export enum MainSections {
    InsertionOrder = 'insertion-order',
    Adspot = 'adspot',
    Fillerspot = 'fillerspot',
    Adblock = 'adblock',
    Audience = 'audience',
    Channel = 'channel',
    Content = 'content',
    Company = 'company',
    History = 'history',
    Industry = 'industry',
    Tags = 'tags',
    Schedule = 'schedule',
    Report = 'report',
}
