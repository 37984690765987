<main class="app">
    <section class="app__section">
        <core-browser-compatibility [browserslist]="browserslist"></core-browser-compatibility>
        <atv-new-version></atv-new-version>
    </section>
    <section class="app__section">
        <core-sidenav>
            <ng-container navigation--sidebar-content>
                <core-sidenav-menu></core-sidenav-menu>
            </ng-container>
            <ng-container navigation-toolbar>
                <core-main-toolbar>
                    <ng-container core-main-toolbar-actions>
                        <div class="vm-display-flex">
                            <atv-localization></atv-localization>
                            <atv-user-options></atv-user-options>
                        </div>
                    </ng-container>
                </core-main-toolbar>
                <atv-request-loading-indicator class="app__loadingIndicator"></atv-request-loading-indicator>
            </ng-container>
            <div class="app__content">
                <router-outlet></router-outlet>
            </div>
        </core-sidenav>
    </section>
</main>
