import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'atv-confirmation-dialog',
    templateUrl: './atv-confirmation-dialog.component.html',
})
class AtvConfirmationDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<AtvConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogConfirmationData,
    ) {
        this.dialogRef.addPanelClass(['dialog-container', 'dialog-container--small']);
        this.data = this.data || {};

        if (this.data.hasCancelButton === undefined) {
            this.data.hasCancelButton = true;
        }

        if (!this.dialogRef.disableClose) {
            this.dialogRef.backdropClick().subscribe(() => {
                if (this.data.hasCancelButton) {
                    this.dialogRef.close(false);
                } else {
                    this.dialogRef.close(true);
                }
            });
        }
    }
}

interface IDialogConfirmationData {
    title?: string;
    description: string;
    confirmButtonLabel?: string;
    cancelButtonLabel?: string;
    hasCancelButton?: boolean;
}

export {AtvConfirmationDialogComponent, IDialogConfirmationData};
