import {HttpErrorResponse} from '@angular/common/http';
import {ISnackbarDetailedErrorMessages} from '@shared/ui/error-snackbar/error-snackbar.component';

interface IApiErrorResponseDetail {
    field: string;
    message: string;
}

export function getMessageFrom400ErrorResponse(event: HttpErrorResponse): ISnackbarDetailedErrorMessages {
    return {
        message: `${event.error.status} ${event.error.reason}: ${event.error.message}`,
        details: event.error.details?.map((detail: IApiErrorResponseDetail) => detail.message) || [],
    };
}
