import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ErrorSnackbarComponent, ISnackbarDetailedErrorMessages} from '@shared/ui/error-snackbar/error-snackbar.component';

type ErrorType = 'SUCCESS' | 'ERROR';

@Injectable({
    providedIn: 'root',
})
class NotificationsService {
    constructor(private snackBar: MatSnackBar) {
    }

    public showSnackbar(message: string, type: ErrorType = 'SUCCESS', duration: number = 6000): void {
        const addClass: string = NotificationsService.setClass(type);

        this.snackBar.open(message, '', {
            verticalPosition: 'bottom',
            horizontalPosition: 'left',
            duration,
            panelClass: ['snack-style', addClass],
        });
    }

    public showErrorDetailsSnackBar(errorMessage: ISnackbarDetailedErrorMessages): void {
        const addClass: string = NotificationsService.setClass('ERROR');

        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            data: errorMessage,
            verticalPosition: 'bottom',
            horizontalPosition: 'left',
            panelClass: ['snack-style', addClass],
        });
    }

    private static setClass(type: string): string {
        return (`snack-style--${type}`).toLowerCase();
    }
}

enum NotificationTypes {
    Success = 'SUCCESS',
    Error = 'ERROR',
}

export {NotificationsService, NotificationTypes};
