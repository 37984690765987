import {environment} from '@env';
import {Configuration, ConfigurationParameters} from '@shared/generated/api/broadcaster';

/**
 * Returns Configuration used by Broadcaster-API-Calls.
 * Configuration contains basePath.
 */
export function getBroadcasterApiConfiguration(): Configuration {
    const credentials: { [key: string]: string | (() => string | undefined) } = {};
    const configurationParameter: ConfigurationParameters = {
        basePath: `${environment.protocol}://${environment.apiUrl}`,
        credentials,
    };

    return new Configuration(configurationParameter);
}
