@if (isNewVersionAvailable) {
    <mat-toolbar-row class="new-version__toolbar vm-gap-1">
        <mat-icon color="primary">info</mat-icon>
        <span class="new-version__title" i18n="@@LAYOUT_NEW_VERSION_AVAILABLE_MESSAGE">LAYOUT_NEW_VERSION_AVAILABLE_MESSAGE</span>
        <button
            type="button"
            color="primary"
            mat-stroked-button
            (click)="refreshPage()"
        >
            <mat-icon>restart_alt</mat-icon>
            <span i18n="@@LAYOUT_NEW_VERSION_AVAILABLE_BUTTON_TEXT">LAYOUT_NEW_VERSION_AVAILABLE_BUTTON_TEXT</span>
        </button>
    </mat-toolbar-row>
}
