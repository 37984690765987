import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {LoadingService} from '@shared/ui/request-loading-indicator/service/loading.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'atv-request-loading-indicator',
    templateUrl: './request-loading-indicator.component.html',
})
export class RequestLoadingIndicatorComponent implements OnInit {

    @Input()
    public showDelayInMilliseconds: number = 1000;
    public isShowing: boolean = false;

    private isLoading: boolean = false;
    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor(private loadingService: LoadingService) {
    }

    public ngOnInit(): void {
        this.loadingService.isLoading()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((isLoading: boolean) => this.changeState(isLoading));
    }

    private changeState(isLoading: boolean) {
        this.isLoading = isLoading;

        if (!isLoading) {
            this.isShowing = false;
        }

        if (isLoading && !this.isShowing) {
            this.showLoadingStateAfterDelay();
        }
    }

    private showLoadingStateAfterDelay(): void {
        setTimeout(() => {
            this.isShowing = this.isLoading;
        }, this.showDelayInMilliseconds);
    }
}
