<button type="button" mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu" class="menu-panel--no-padding" xPosition="before">
    <mat-selection-list class="selection-menu" hideSingleSelectionIndicator="true" multiple="false">
        <mat-list-item
            class="user-options__info-header mat-elevation-z2"
            disableRipple="true"
            (click)="$event.stopPropagation()"
        >
            <mat-icon matListItemIcon>account_circle</mat-icon>
            <span matListItemTitle>{{userName}}</span>
            {{broadcasterName}} ({{broadcasterId}})
        </mat-list-item>
        <mat-list-option (click)="logout()">
            <mat-icon matListItemIcon>logout</mat-icon>
            <span matListItemTitle i18n="@@USER_OPTIONS_LOGOUT_BUTTON_LABEL">USER_OPTIONS_LOGOUT_BUTTON_LABEL</span>
        </mat-list-option>
    </mat-selection-list>
</mat-menu>
