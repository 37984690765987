import {environment} from '@env';
import {Configuration, ConfigurationParameters} from '@shared/generated/api/booking';
import {LocalStorageService} from '@shared/services/local-storage.service';
import {LocalStorageKeys} from '@shared/enums/local-storage-keys';

/**
 * Returns Configuration used by API-Calls.
 * Configuration contains basePath and broadcaster_header (credentials.broadcaster_header).
 */
export function getBookingApiConfiguration(): Configuration {
    const credentials: { [key: string]: string | (() => string | undefined) } = {};
    const configurationParameter: ConfigurationParameters = {
        basePath: `${environment.protocol}://${environment.apiUrl}`,
        credentials,
    };

    credentials.broadcaster_header = (): string => {
        return LocalStorageService.getLocalStorage(LocalStorageKeys.TENANT_ID).toString();
    };

    return new Configuration(configurationParameter);
}
