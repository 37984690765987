import {ActivatedRouteSnapshot, ResolveFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {LocalStorageService} from '@shared/services/local-storage.service';
import {LocalStorageKeys} from '@shared/enums/local-storage-keys';

const redirectGuard = (): void => {
    const router: Router = inject(Router);
    const tenantId: number = LocalStorageService.getLocalStorage(LocalStorageKeys.TENANT_ID);
    void router.navigate([`${tenantId}/insertion-order`]);
};

const tenantIdResolver: ResolveFn<number | null> = (route: ActivatedRouteSnapshot): number | null => {
    const tenantId: number | null = isNaN(Number(route.params.id)) ? null : Number(route.params.id);

    return tenantId;
};

export {redirectGuard, tenantIdResolver};
