import {Component} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {StateService} from '@shared/services/state.service';
import {CustomError} from '@shared/error/custom-error';
import {Broadcaster} from '@shared/generated/api/broadcaster';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'atv-user-options',
    templateUrl: './user-options.component.html',
    styleUrls: ['./user-options.component.scss'],
})
export class AtvUserOptionsComponent {
    public userName!: string;
    public broadcasterName!: string;
    public broadcasterId!: number;
    public availableBroadcasters: Array<Broadcaster> = [];

    constructor(
        private keycloakService: KeycloakService,
        private stateService: StateService,
    ) {
        this.stateService.availableBroadcasters$
            .pipe(takeUntilDestroyed())
            .subscribe(
                (broadcasters: Array<Broadcaster>) => {
                    this.availableBroadcasters = broadcasters;
                },
            );

        this.stateService.currentBroadcaster$
            .pipe(takeUntilDestroyed())
            .subscribe(
                (broadcaster: Broadcaster) => {
                    this.broadcasterName = broadcaster.name;
                    this.broadcasterId = broadcaster.id;
                },
            );

        if (this.keycloakService.isLoggedIn()) {
            this.userName = this.keycloakService.getUsername();
        }
    }

    public logout(): void {
        this.keycloakService.logout()
            .catch((err: Error) => {
                throw new CustomError(err.message, 'AtvUserOptionsContentComponent');
            });
    }
}
