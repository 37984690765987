import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public isLoading(): Observable<boolean> {
        return this._isLoading;
    }
    public setLoading(isLoading: boolean): void {
        this._isLoading.next(isLoading);
    }
}
