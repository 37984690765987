import {Component, Inject, LOCALE_ID} from '@angular/core';
import {Router} from '@angular/router';
import {Url} from '@shared/utilities/url';
import {DOCUMENT} from '@angular/common';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'atv-localization',
    templateUrl: './localization.component.html',
})
class AtvLocalizationComponent {
    public locale: string = Locale.EN;
    public languageOptions: Array<{key: Locale; value: string}> = [{
        key: Locale.DE,
        value: $localize`:@@LOCALIZATION_LANGUAGE_OPTION_GERMAN:LOCALIZATION_LANGUAGE_OPTION_GERMAN`,
    }, {
        key: Locale.EN,
        value: $localize`:@@LOCALIZATION_LANGUAGE_OPTION_ENGLISH:LOCALIZATION_LANGUAGE_OPTION_ENGLISH`,
    }, {
        key: Locale.FR,
        value: $localize`:@@LOCALIZATION_LANGUAGE_OPTION_FRENCH:LOCALIZATION_LANGUAGE_OPTION_FRENCH`,
    }];

    constructor(
        private router: Router,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        @Inject(DOCUMENT) private document: Document,
        @Inject(Url) private url: Url,
        @Inject(LOCALE_ID) private localeId: string,

    ) {
        this.locale = localeId;
        this.registerLanguageIcons();
    }

    public switchLanguage(language: string): void {
        const path: string = this.router.url;
        let url: string = `${this.url.getUrlBase()}${language}${path}`;

        if (!this.document.location.pathname.endsWith('/')) {
            url += '/';
        }

        this.document.location.assign(url);
    }

    private registerLanguageIcons(): void {
        this.matIconRegistry.addSvgIcon('de', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/de-flag.svg'));
        this.matIconRegistry.addSvgIcon('en', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/en-flag.svg'));
        this.matIconRegistry.addSvgIcon('fr', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/fr-flag.svg'));
    }
}

enum Locale {
    EN = 'en',
    DE = 'de',
    FR = 'fr',
}

export {AtvLocalizationComponent};
