class CustomError extends Error {
    private component: string | undefined = '';
    private data: object | undefined = undefined;
    private tags: ISentryTag | Array<ISentryTag> | undefined = undefined;
    private dialog: boolean = false;

    constructor(
        message: string,
        component?: string,
        data?: object,
        tags?: ISentryTag | Array<ISentryTag>,
        dialog: boolean = false,
    ) {
        super(message);
        this.component = component;
        this.data = data;
        this.tags = tags;
        this.dialog = dialog;
    }
}

interface ISentryTag {
    key: string;
    value: string;
}

export {CustomError, ISentryTag};
