import {NgModule} from '@angular/core';
import {AtvLocalizationComponent} from './localization.component';
import {Url} from '@shared/utilities/url';
import {CommonModule} from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';

@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
    ],
    declarations: [
        AtvLocalizationComponent,
    ],
    exports: [
        AtvLocalizationComponent,
    ],
    providers: [
        Url,
    ],
})
class AtvLocalizationModule {
}

export {AtvLocalizationModule};
