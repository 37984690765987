import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogConfig, MatDialogModule} from '@angular/material/dialog';
import {CdkStepper} from '@angular/cdk/stepper';
import {AtvConfirmationDialogComponent} from '@shared/ui/dialogs/confirmation/atv-confirmation-dialog.component';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
        AtvConfirmationDialogComponent,
    ],
    exports: [
        AtvConfirmationDialogComponent,
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
    ],
    providers: [
        MatDialogConfig,
        CdkStepper,
    ],
})
export class AtvConfirmationDialogModule {
}
