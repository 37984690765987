import {Broadcaster} from '@shared/generated/api/broadcaster';

enum UserRoles {
    Admin = 'ADMIN',
    Creator = 'CREATOR',
    Editor = 'EDITOR',
    Visitor = 'VISITOR',
    AudienceManager = 'AUDIENCE_MANAGER',
}

enum ReportingUserRoles {
    Admin = 'ADMIN',
    Visitor = 'VISITOR',
    ReportingSimulation = 'REPORTING_SIMULATION'
}

enum UserResourceAccessRoles {
    BookingApi = 'booking-api',
    ReportingApi = 'reporting-api'
}

class User implements IUser {
    private readonly _id: number | null;
    private _firstName: string = '';
    private _lastName: string = '';
    private _username: string = '';
    private _email: string = '';
    private _broadcasters: Array<Broadcaster> = [];

    constructor(
        id: number | null,
    ) {
        this._id = id;
    }

    get id(): number | null {
        return this._id;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get broadcasters(): Array<Broadcaster> {
        return this._broadcasters;
    }

    set broadcasters(value: Array<Broadcaster>) {
        this._broadcasters = value;
    }
}

interface IUser {
    id: number | null;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    broadcasters: Array<Broadcaster>;
}

export {IUser, User, UserRoles, ReportingUserRoles, UserResourceAccessRoles};
