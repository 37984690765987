import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoadingService} from '../service/loading.service';
import {finalize} from 'rxjs/operators';

@Injectable()
export class RequestLoadingInterceptor implements HttpInterceptor {

    private pendingRequests: Array<HttpRequest<unknown>> = [];

    constructor(private loaderService: LoadingService) { }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.addPendingRequest(request);
        this.updateLoadingService();

        return next.handle(request).pipe(
            finalize(() => {
                this.removePendingRequest(request);
                this.updateLoadingService();
            }),
        );
    }

    private addPendingRequest(request: HttpRequest<unknown>): void {
        this.pendingRequests.push(request);
    }

    private removePendingRequest(req: HttpRequest<unknown>): void {
        const indexOfRequest: number = this.pendingRequests.indexOf(req);
        if (indexOfRequest >= 0) {
            this.pendingRequests.splice(indexOfRequest, 1);
        }
    }

    private updateLoadingService(): void {
        this.loaderService.setLoading(this.pendingRequests.length > 0);
    }
}
