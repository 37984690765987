import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@Component({
    standalone: true,
    imports: [CommonModule, MatTooltipModule, MatIconModule, MatButtonModule],
    selector: 'error-snackbar',
    templateUrl: 'error-snackbar.component.html',
    styleUrls: ['error-snackbar.component.scss'],
})
class ErrorSnackbarComponent {
    constructor(
        public snackBarRef: MatSnackBarRef<ErrorSnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarDetailedErrorMessages,
    ) {}
}

interface ISnackbarDetailedErrorMessages {
    message: string;
    details: Array<string>;
}

export {ErrorSnackbarComponent, ISnackbarDetailedErrorMessages};
