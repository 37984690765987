/**
 * Virtualminds Media Manager Broadcaster API
 * This API provides endpoints in the context of broadcasters.  # Authentication The Media Manager Broadcaster API uses Bearer Tokens, as specified by OAuth 2.0, to authenticate requests. Bearer tokens must be generated and passed with every API request.  ## Generating a Bearer Token A bearer token is generated by our single sign-on endpoint, as show in this example cURL request. Just replace `<USERNAME>` and `<PASSWORD>` and send this request in your command line tool: ``` curl --request POST \'https://login.mm.virtualminds.com/auth/realms/adition-tv/protocol/openid-connect/token\' \\ --header \'Content-Type: application/x-www-form-urlencoded\' \\ --data-urlencode \'client_id=booking-gui\' \\ --data-urlencode \'username=<USERNAME>\' \\ --data-urlencode \'password=<PASSWORD>\' \\ --data-urlencode \'grant_type=password\' ``` The generated bearer token is provided in the `access_token` field of the response.  Bearer tokens are set to expire after 30 minutes.  ## Bearer Tokens and different environments The cURL request above, produces a bearer token which authenticates a user in our *production environment only*.  To authenticate yourself in our Staging or Production environment, use `https://login.mm-stg.virtualminds.com/auth/realms/adition-tv/protocol/openid-connect/token` and `https://login.mm.virtualminds.com/auth/realms/adition-tv/protocol/openid-connect/token` respectively.  ## Further documentation for OpenID Connect Our single sign-on endpoint adheres to the OpenID Connect (OIDC) protocol which allows additional, standardized operations such as retrieving or refreshing a bearer token.  The details of our OIDC connect endpoint are described in the `Connect URL`s below:   - Production: [ login.mm.virtualminds.com ](https://login.mm.virtualminds.com/auth/realms/adition-tv/.well-known/openid-configuration)   - Staging: [ login.mm-stg.virtualminds.com ](https://login.mm-stg.virtualminds.com/auth/realms/adition-tv/.well-known/openid-configuration) 
 *
 * The version of the OpenAPI document: 1.19.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Broadcaster { 
    /**
     * The unique identifier of the broadcaster.
     */
    readonly id: number;
    /**
     * The name of the broadcaster.
     */
    name: string;
    /**
     * The description of the broadcaster.
     */
    description?: string;
    /**
     * The name of the timezone.
     */
    readonly timezone: string;
    /**
     * The start calendar day.
     */
    calendarDayStart?: number;
    /**
     * `DEFAULT` works best for defining targetings and pacing manually; `HOLISTIC` works best if not influenced by targetings or pacing settings.
     */
    distributionModel?: BroadcasterDistributionModelEnum;
    /**
     * The date and time (ISO-8601 UTC with offset), when the broadcaster was created.
     */
    readonly createdAt?: string;
    /**
     * The date and time (ISO-8601 UTC with offset) when the broadcaster was last updated.
     */
    readonly updatedAt?: string;
}
export enum BroadcasterDistributionModelEnum {
    Default = 'DEFAULT',
    Holistic = 'HOLISTIC'
};



